<template>
  <div class="icon-bg">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="12" cy="12" r="10" fill="#A7A9C0" />
      <path
        d="M16.769 7.81769C17.1458 7.41393 17.7786 7.39211 18.1823 7.76895C18.5861 8.14578 18.6079 8.77857 18.2311 9.18232L11.2311 16.6823C10.8655 17.074 10.2561 17.108 9.84923 16.7593L6.34923 13.7593C5.9299 13.3998 5.88134 12.7685 6.24076 12.3492C6.60018 11.9299 7.23148 11.8813 7.65081 12.2407L10.423 14.6169L16.769 7.81769Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconIsp",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }

  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
